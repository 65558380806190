<template>
    <section class="invoice-preview-wrapper">
  
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="paymentData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching payment data
        </h4>
        <div class="alert-body">
          No payment found with this payment id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'apps-payment-list'}"
          >
            Payment List
          </b-link>
          for other payments.
        </div>
      </b-alert>
  
      <b-row
        class="invoice-preview"
      >
  
        <!-- Col: Left (Payment Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
  
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
                <!-- Header: Left Content -->
                <div v-if="churchData">
                  <div v-if="churchData.logo" class="logo-wrapper mb-0">
                    <b-img style="width:180px" :src="churchData.logo" />
                  </div>
                  <div v-else class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Asorefie
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ churchData.name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ churchData.addressName }}
                  </p>
                  <p v-if="churchData.email" class="card-text mb-0">
                    <b-link
                      :href="`mailto:${churchData.email}`"
                      class="font-weight-bold"
                    >
                      {{ churchData.email }}
                    </b-link>
                  </p>
                  <p class="card-text mb-0">
                    <span
                      class="font-weight-bold"
                    >
                      {{ churchData.contact1.countryCode+churchData.contact1.tel }}
                    </span>
                    
                    <span
                      v-if="churchData.contact2"
                      class="font-weight-bold mt-2"
                    >
                    ,  {{ churchData.contact2.countryCode+churchData.contact2.tel }}
                  </span>
                  </p>
                </div>
  
                <!-- Header: Right Content -->
                <div v-if="paymentData" class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Payment 
                    <span class="invoice-number">#{{ paymentData.id }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Payment Date:
                    </p>
                    <p class="invoice-date">
                      {{ paymentData._paymentDate }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Status:
                    </p>
                    <p class="invoice-date">
                        <b-badge
                            pill
                            :variant="`light-${resolvePaymentStatusVariant(paymentData.status)}`"
                            class="text-capitalize"
                        >
                            {{ paymentData.status }}
                        </b-badge>
                    </p>
                  </div>
                </div>

                <div v-else-if="recordData" class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{  action == 'approvals' ? 'Payment Summary:' : 'Payment Preview:' }} 
                  <span class="invoice-number text-primary"> {{ recordData.type ? recordData.type.substring(0,1).toUpperCase()+recordData.type.substring(1) : ''}}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Search:
                  </p>
                  <p class="invoice-date">
                    {{ recordData.q }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Range:
                  </p>
                  <p class="invoice-date">
                    {{ recordData.range }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Status:
                    </p>
                    <p class="invoice-date">
                        <b-badge
                            pill
                            :variant="`light-${resolvePaymentStatusVariant(recordData.status)}`"
                            class="text-capitalize"
                        >
                            {{ recordData.status }}
                        </b-badge>
                    </p>
                  </div>
              </div>

              </div>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing">
  
            <!-- Payment Client & Payment Details -->
            <b-card-body
              v-if="false"
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">
  
                <!-- Col: Payment To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    Payment Request By:
                  </h6>
                  <h6 class="mb-25">
                    {{ paymentData.member.member }}
                  </h6>
                  <p class="card-text mb-25">
                    #{{ paymentData.member.memID }}
                  </p>
                  <p class="card-text mb-25">
                    {{ paymentData.member.contact1.countryCode+paymentData.member.contact1.tel }}
                  </p>
                </b-col>
  
                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">
                      Payment Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bold">{{ paymentData.amount }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Type:
                          </td>
                          <td>{{ paymentData.payment }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Details:
                          </td>
                          <!-- <td>{{ paymentData.paymentDetails }}</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
  
            <!-- Payment Description: Table -->
            <b-table-lite
              v-if="paymentData"
              responsive
              :items="[paymentData]"
              :fields="[
                { key: 'type', label: 'Description' }, 
                { key: 'payment', label: 'payment' },
                { key: 'amount', label: 'amount' },
                { key: '_paymentDate', label: 'payment Date' }]"
            >
              <template #cell(type)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.type.substring(0,1).toUpperCase()+data.item.type.substring(1) }} {{ ' : '+data.item._date }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.paymentFor }}
                </b-card-text>
              </template>
  
              <template #cell(payment)="data">
                <b-badge :variant="`${resolvePaymentPaymentVariant(data.item.payment)}`">{{ resolvePaymentVariant(data.item.payment) }}</b-badge>
              </template>

              <template #cell(amount)="data">
                <span class="font-weight-bold">
                  {{ formatAmount(data.item.amount) }}
                </span>
              </template>
  
              
            </b-table-lite>

            <b-table-lite
             v-else-if="['preview', 'approvals'].includes(action) && records"
            responsive
            :items="records"
            :fields="[
              { key: 'id', label: 'ID' }, 
              { key: 'type', label: 'Type/Description' }, 
              { key: 'category', label: 'category' }, 
              { key: 'payment', label: 'payment' },
              { key: 'amount', label: 'amount' },
              { key: '_paymentDate', label: 'Payment Date' }]"
          >
            <template #cell(id)="data">
              <!-- <b-link
                :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
                class="font-weight-bold"
              > -->
                <!-- #{{ data.value }} -->
                {{ data.index+1 }}
              <!-- </b-link> -->
            </template>
            
            <template #cell(type)="data">
              <b-card-text class="font-weight-bold mb-25 text-primary">
                {{ data.item.type.substring(0,1).toUpperCase()+data.item.type.substring(1) }} {{ ['tithe', 'welfare dues'].includes(data.item.type) ? ': '+data.item._date.substring(3) : '' }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.paymentDetails }}
              </b-card-text>
            </template>

            <template #cell(payment)="data">
              <b-badge :variant="`${resolvePaymentPaymentVariant(data.item.payment)}`">{{ resolvePaymentVariant(data.item.payment) }}</b-badge>
            </template>

            <template #cell(amount)="data">
              <p class="font-weight-bold text-right mb-0">
                {{ formatAmount(data.item.amount) }}
              </p>
            </template>
            
          </b-table-lite>
  
            <!-- Payment Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ records ? records.reduce((a, b) => a + b.amount, 0) : paymentData.amount }}
                      </p>
                    </div> -->
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div> -->
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ records.length > 0 ? formatAmount(recordData.totalAmount) : formatAmount(paymentData.amount) }}
                      </p>
                    </div>
                  </div>
                </b-col>
                <!-- Col: Sales Persion -->
                <b-col
                  v-if="paymentData"
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text  class="mt-3">
                    <span class="font-weight-bold">By:</span>
                    <span class="ml-75">{{ paymentData.by }}</span>
                  </b-card-text>
                  <b-card-text v-if="paymentData.approved">
                    <span class="font-weight-bold">Approved By:</span>
                    <span class="ml-75">{{ paymentData.approved }}</span>
                  </b-card-text>
                  <b-card-text v-else-if="paymentData.cancelled">
                    <span class="font-weight-bold">Cancelled By:</span>
                    <span class="ml-75">{{ paymentData.cancelled }}</span>
                  </b-card-text>
                  <b-card-text v-if="paymentData.cancelReason">
                    <span class="font-weight-bold">Cancel Reason:</span>
                    <span class="ml-75">{{ paymentData.cancelReason }}</span>
                  </b-card-text>
                </b-col>
                <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-5"
                order="2"
                order-md="1"
                v-else>

            </b-col>
  
                
              </b-row>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing">
  
            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <span>It was a pleasure serving you and your team. Thank You!</span>
            </b-card-body>
          </b-card>
        </b-col>
  
        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
  
            <!-- Button: Send Payment -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-payment
              variant="primary"
              class="mb-75"
              block
              :to="{ name: 'accounts-payment-list' }"
            >
              View Payments
            </b-button>
  
            <!-- Button: DOwnload -->
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
            >
              Download
            </b-button> -->
  
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="printPayment"
            >
              Print
            </b-button>
  
            <!-- Button: Edit -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              :to="{ name: 'accounts-payment-list', params: { id: $route.params.id } }"
            >
              Edit
            </b-button>
  
            <!-- Button: Add Payment -->
            <!-- <b-button
              v-b-toggle.sidebar-payment-add-payment
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
            >
              Add Payment
            </b-button> -->
          </b-card>
        </b-col>
      </b-row>
  
      <!-- <payment-sidebar-send-payment /> -->
    </section>
  </template>
  
  <script>
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import router from '@/router'
  import {
    BRow, BCol, BCard, BBadge, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg
  } from 'bootstrap-vue'
  import Logo from '@core/layouts/components/Logo.vue'
  import Ripple from 'vue-ripple-directive'
  import paymentStoreModule from '../paymentStoreModule'
  // import PaymentSidebarSendPayment from '../paymentSidebarSend.vue'
  import { formatAmount } from '@core/comp-functions/formatAmount'
  
  export default {
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    components: {
      BRow,
      BCol,
      BCard,
      BBadge,
      BCardBody,
      BTableLite,
      BCardText,
      BButton,
      BAlert,
      BLink,
      BImg,
      Logo,
      // PaymentSidebarSendPayment,
    },
    setup() {
      const churchData = ref(null)
      const paymentData = ref(null)
      const records = ref([])
      const recordData = ref(null)
      const action = ref(null)
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-payment'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, paymentStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
      })
  
      if (router.currentRoute.params.id !== "0") {
  
        store.dispatch('account-payment/fetchPayment', { id: router.currentRoute.params.id })
          .then(response => {
            paymentData.value = response.data.payment
          })
          .catch(error => {
            if ([404, 400, 500].includes(error.response.status)) {
              receiptData.value = undefined
            }
          })
  
        store.dispatch('account-payment/churchInfo')
          .then(response => {
            churchData.value = response.data
          })
          .catch(error => {
            if ([404, 400, 500].includes(error.response.status)) {
              receiptData.value = undefined
            }
          })
      }

      else if (router.currentRoute.params.action == 'preview' ) {

        store.dispatch('account-payment/churchInfo')
          .then(response => {
            churchData.value = response.data
          })
          .catch(error => {
            if ([404, 400, 500].includes(error.response.status)) {
              churchData.value = undefined
            }
          }) 

        action.value = router.currentRoute.params.action

        // fetchReceipts()
        recordData.value = router.currentRoute.params.params

        const { payments, totalAmount } = store.getters['account-payment/getPayments'](router.currentRoute.params.params)
        records.value =  payments
        recordData.value.totalAmount = totalAmount

        console.log(router.currentRoute.params)
        console.log(records.value)


      }

      else if (router.currentRoute.params.action == 'approvals' ) {

        store.dispatch('account-payment/churchInfo')
          .then(response => {
            churchData.value = response.data
          })
          .catch(error => {
            if ([404, 400, 500].includes(error.response.status)) {
              churchData.value = undefined
            }
          }) 

        action.value = router.currentRoute.params.action

        // fetchReceipts()
        recordData.value = router.currentRoute.params.params

        const { approvals, atotalAmount } = store.getters['account-payment/getApprovals'](router.currentRoute.params.params)
        records.value =  approvals
        recordData.value.totalAmount = atotalAmount

        console.log(router.currentRoute.params)
        // console.log(records.value)


      }
  
      const printPayment = () => {
        window.print()
      }
  
      const resolvePaymentPaymentVariant =  payment => {
        if (payment === 'cash') return 'primary'
        if (payment === 'mobile_money') return 'warning'
        if (payment === 'bank') return 'info'
        return 'primary'
      }
  
      const resolvePaymentStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'approved') return 'success'
        if (status === 'cancelled') return 'danger'
        return 'secondary'
      }

      const resolvePaymentVariant = payment => {
            if (payment === 'cash') return 'Cash'
            if (payment === 'mobile_money') return 'Mobile Money'
            if (payment === 'bank') return 'Bank'
        }
  
      return {
        churchData,
        paymentData,
        printPayment,
        resolvePaymentPaymentVariant,
        resolvePaymentStatusVariant,
        resolvePaymentVariant,
        records,
        recordData,
        action,
        formatAmount
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Payment Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
  
      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
  